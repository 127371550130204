import React, { Component } from "react";

import "./App.css";
const sharksGif = require('./images/sharks.gif');

class App extends Component {
  state = {

  };

  render() {
    return (
      <div className="App">
        <div id="body">
          <img src={sharksGif} width={500} height={500} className="sharks" alt="Smol Sharks"/>

          <div className="links">
            <a href="https://twitter.com/smolsharks" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://discord.gg/gCxRU8AWAY" target="_blank" rel="noopener noreferrer">Discord</a>
            <a href="https://docs.smolsharks.lol" target="_blank" rel="noopener noreferrer">Docs</a>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
